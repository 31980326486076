@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@1,700&display=swap');

body {
  background: white;
  font-family: 'Merriweather', serif;
  color: white;
  margin: 0;
  padding: 0;
}

html {
  scroll-snap-type: y mandatory;
}